



















import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'AppLoadingSpinner',
  props: {
    size: {
      type: [Number, String],
      default: 32,
    },
    width: {
      type: [Number, String],
      default: 4,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    card: Boolean,
    plain: Boolean,
  },
  setup(props) {
    const classes = computed(() => {
      return {
        'ma-4': !props.plain,
      };
    });

    return {
      classes,
    };
  },
});
