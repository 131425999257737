











import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';
import AppLoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import AppDeploymentViewer from '@/components/project/files/DeploymentViewer.vue';
import { Project, Branch, Deployment } from '@/model/store';
import { useProjectsStore } from '@/stores/projects';

export default defineComponent({
  name: 'ProjectFiles',
  components: { AppDeploymentViewer, AppLoadingSpinner },
  props: {
    isSetup: Boolean,
  },
  setup(props, { root }) {
    const projectsStore = useProjectsStore();
    const { projectId, branchId, deploymentId } = root.$route.params;
    const loading = ref<boolean>(false);
    const project = computed<Project>(() => {
      return projectsStore.projectById(projectsStore.deploymentViewerSettings.projectId);
    });
    const branch = computed<Branch>(() => {
      return projectsStore.branchById(
        projectsStore.deploymentViewerSettings.projectId,
        projectsStore.deploymentViewerSettings.branchId
      );
    });
    const deployment = computed<Deployment>(() => {
      return projectsStore.deploymentById(
        projectsStore.deploymentViewerSettings.projectId,
        projectsStore.deploymentViewerSettings.branchId,
        projectsStore.deploymentViewerSettings.deploymentId
      );
    });

    onMounted(() => {
      loading.value = true;
      projectsStore.selectProject(projectId).then(() => {
        projectsStore.deploymentViewerSettings = {
          projectId,
          branchId,
          deploymentId,
        };
        loading.value = false;
      });
    });

    return {
      loading,
      project,
      branch,
      deployment,
    };
  },
});
