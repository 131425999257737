

























import { computed, defineComponent, onMounted } from '@vue/composition-api';
import { filetypemime, filetypename } from 'magic-bytes.js';
import Prism from 'prismjs';
import AppErrorAlert from '@/components/project/files/ErrorAlert.vue';

const defaultLanguage = 'text';
const additionalMimeTypes = {
  svg: 'image/svg+xml',
  ico: 'image/x-icon',
};

export default defineComponent({
  name: 'AppFileContents',
  components: { AppErrorAlert },
  props: {
    path: {
      type: String,
      required: true,
    },
    fileContents: {
      type: String,
      required: true,
    },
    fileSize: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const displayedFileSize = 128 * 1024;

    const fileContentBuffer = computed<number[]>(() => {
      return Buffer.from(props.fileContents, 'base64').toJSON().data;
    });

    const mimeType = computed<string | undefined>(() => {
      if (!props.fileContents) {
        return undefined;
      }
      const mimeTypes = filetypemime(fileContentBuffer.value).filter((type) => !!type);
      if (mimeTypes.length > 0) {
        return mimeTypes[0];
      }
      if (fileExtension.value) {
        return additionalMimeTypes[fileExtension.value];
      }
      return undefined;
    });

    const fileExtension = computed<string | undefined>(() => {
      return props.fileContents ? props.path.split('.').pop() : undefined;
    });

    const fileContentLanguage = computed<string>(() => {
      if (!props.fileContents) {
        return defaultLanguage;
      }
      const fileTypes = filetypename(fileContentBuffer.value).filter((type) => !!type);
      if (fileTypes.length > 0) {
        return fileTypes[0];
      }
      return fileExtension.value || defaultLanguage;
    });

    const languageClass = computed<object>(() => {
      return {
        [`language-${fileContentLanguage.value}`]: fileContentLanguage.value,
      };
    });

    const lineNumberWidth = computed<number>(() => {
      if (!props.fileContents) {
        return 0;
      }
      const lineCount = fileContentLines.value.length;
      const charWidth = (Math.floor(Math.log10(lineCount)) + 1) * 10;
      return charWidth + 10;
    });

    const fileContentLines = computed<string[]>(() => {
      if (props.fileContents) {
        return decodeFileContents(props.fileContents)
          .slice(0, displayedFileSize)
          .split('\n')
          .map((line) => line || '\n');
      }
      return [];
    });

    const fileIsImage = computed<boolean>(() => {
      return !!mimeType.value && mimeType.value.startsWith('image/');
    });

    const fileSizeIsTooLarge = computed<boolean>(() => {
      return props.fileSize > displayedFileSize;
    });

    const canHighlightFiles = computed<boolean>(() => {
      return !!props.fileContents && !fileSizeIsTooLarge.value && !fileIsImage.value;
    });

    const decodeFileContents = (contents: string): string => {
      return atob(contents);
    };

    const highlightFileContents = () => {
      if (canHighlightFiles.value) {
        Prism.highlightAll();
      }
    };

    const imageSource = (contents: string) => {
      return `data:${mimeType.value};charset=utf-8;base64,${contents}`;
    };

    onMounted(() => {
      highlightFileContents();
    });

    return {
      fileIsImage,
      fileSizeIsTooLarge,
      lineNumberWidth,
      fileContentLines,
      languageClass,
      imageSource,
    };
  },
});
