

























import { computed, defineComponent, PropType } from '@vue/composition-api';
import AppDownloadButton from '@/components/project/files/DownloadButton.vue';
import { Branch, Deployment, Project } from '@/model/store';

interface BreadCrumb {
  name: string;
  path?: string;
}

export default defineComponent({
  name: 'AppDirectoryHeader',
  components: { AppDownloadButton },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    branch: {
      type: Object as PropType<Branch>,
      required: true,
    },
    deployment: {
      type: Object as PropType<Deployment>,
    },
    path: {
      type: String,
      required: true,
    },
    fileContents: String,
    loading: Boolean,
    withDownloadButton: Boolean,
    delimiter: {
      type: String,
      default: '/',
    },
  },
  emits: ['navigate'],
  setup(props, { emit }) {
    const projectName = computed<string>(() => {
      return props.project.name || '';
    });
    const fileName = computed<string>(() => {
      return props.path.split(props.delimiter).pop() || '';
    });
    const breadcrumbs = computed<BreadCrumb[]>(() => {
      const segments = props.path.split(props.delimiter).filter((path) => !!path);
      const lastSegmentIndex = segments.length - 1;
      return segments.map((segment, index) => ({
        name: segment,
        path: index === lastSegmentIndex ? undefined : segments.slice(0, index + 1).join(props.delimiter),
      }));
    });

    const onDeploymentSelect = (deployment: Deployment): void => {
      emit('navigate', props.path, deployment);
    };

    return {
      projectName,
      fileName,
      breadcrumbs,
      onDeploymentSelect,
    };
  },
});
