








































import { computed, ref, defineComponent, PropType } from '@vue/composition-api';
import { Branch, Deployment, Project } from '@/model/store';
import { useProjectsStore } from '@/stores/projects';

export default defineComponent({
  name: 'AppBranchSelect',
  emits: ['select'],
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    branch: {
      type: Object as PropType<Branch>,
      required: true,
    },
    deployment: {
      type: Object as PropType<Deployment>,
    },
  },
  setup(props, { emit }) {
    const projectsStore = useProjectsStore();

    const selectBranchRef = ref(undefined);
    const selectedBranch = ref<Branch>(props.branch);
    const selectedBranchId = ref<string>(props.branch.id);
    const selectDeploymentRef = ref(undefined);
    const selectedDeployment = ref<Deployment | undefined>(props.deployment);
    const selectedDeploymentId = ref<string | undefined>(props.deployment?.id);

    const branches = computed<Branch[]>(() => {
      return projectsStore.allBranchesOfProject(props.project.id)?.filter((branch: Branch) => {
        return branch.deploymentCount > 0 && !branch.deleted;
      });
    });
    const deployments = computed<Deployment[]>(() => {
      if (selectedBranch.value.deploymentCount > 1) {
        return selectedBranch.value.deployments;
      }
      return [];
    });

    const branchSelected = () => {
      (selectBranchRef.value as any).blur();
      const branch = projectsStore.branchById(props.project.id, selectedBranchId.value);
      if (branch) {
        selectedBranch.value = branch;
        selectedDeployment.value = branch.deployments[0];
        selectedDeploymentId.value = branch.deployments[0].id;
        emit('select', selectedDeployment.value);
      }
    };

    const deploymentSelected = () => {
      (selectDeploymentRef.value as any).blur();
      const deployment = projectsStore.deploymentById(
        props.project.id,
        selectedBranchId.value,
        selectedDeploymentId.value
      );
      if (deployment) {
        selectedDeployment.value = deployment;
        emit('select', selectedDeployment.value);
      }
    };

    return {
      selectBranchRef,
      selectDeploymentRef,
      selectedBranchId,
      selectedDeploymentId,
      branchSelected,
      deploymentSelected,
      branches,
      deployments,
    };
  },
});
