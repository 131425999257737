


































import { defineComponent, PropType } from '@vue/composition-api';
import { Folder, File } from 'waas-directory-service-api';
import { formatDate, prettyBytes } from '@/utils';

export default defineComponent({
  name: 'AppDirectoryContents',
  props: {
    folders: {
      type: Array as PropType<Folder[]>,
      required: true,
    },
    files: {
      type: Array as PropType<File[]>,
      required: true,
    },
  },
  setup() {
    return {
      prettyBytes,
      formatDate,
    };
  },
});
