







import { computed, defineComponent, onBeforeUnmount } from '@vue/composition-api';

export default defineComponent({
  name: 'AppDownloadButton',
  props: {
    fileName: {
      type: String,
      required: true,
    },
    fileContents: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const downloadUrl = computed<string>(() => {
      const blob = new Blob([decodedFileContents.value], { type: 'application/octet-stream' });
      return URL.createObjectURL(blob);
    });
    const decodedFileContents = computed<Buffer>(() => {
      return Buffer.from(props.fileContents, 'base64');
    });

    onBeforeUnmount(() => {
      URL.revokeObjectURL(downloadUrl.value);
    });

    return {
      downloadUrl,
    };
  },
});
