
















import { defineComponent, PropType } from '@vue/composition-api';
import AppBranchSelect from '@/components/project/files/BranchSelect.vue';
import { Branch, Deployment, Project } from '@/model/store';

export default defineComponent({
  name: 'AppNavigation',
  components: { AppBranchSelect },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    branch: {
      type: Object as PropType<Branch>,
      required: true,
    },
    deployment: {
      type: Object as PropType<Deployment>,
    },
    path: {
      type: String,
      required: true,
    },
  },
  emits: ['navigate'],
  setup(props, { emit }) {
    const onDeploymentSelect = (deployment: Deployment): void => {
      emit('navigate', props.path, deployment);
    };

    return {
      onDeploymentSelect,
    };
  },
});
