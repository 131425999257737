









































import { ref, computed, defineComponent, PropType, onMounted } from '@vue/composition-api';
import { Branch, Deployment, Project } from '@/model/store';
import AppDirectoryHeader from '@/components/project/files/DirectoryHeader.vue';
import AppNavigation from '@/components/project/files/Navigation.vue';
import AppErrorAlert from '@/components/project/files/ErrorAlert.vue';
import AppFileContents from '@/components/project/files/FileContents.vue';
import AppDirectoryContents from '@/components/project/files/DirectoryContents.vue';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useI18n } from 'vue-i18n-bridge';
import { File, Folder, FileSystemResource } from 'waas-directory-service-api';
import * as _ from 'lodash';
import { directoryApiClient } from '@/plugins/axios';
import { useProjectsStore } from '@/stores/projects';

export default defineComponent({
  name: 'AppDeploymentViewer',
  components: { AppDirectoryContents, AppFileContents, AppErrorAlert, AppDirectoryHeader, AppNavigation },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    branch: {
      type: Object as PropType<Branch>,
      required: true,
    },
    deployment: {
      type: Object as PropType<Deployment>,
    },
  },
  setup(props, { root }) {
    const projectsStore = useProjectsStore();
    const { t } = useI18n();
    const router = root.$router;
    const route = root.$route;
    const apiCallIsRunning = ref<boolean>(false);
    const loading = computed<boolean>(() => {
      return apiCallIsRunning.value || !props.deployment;
    });
    const constants = {
      backPath: '..',
      delimiter: '/',
    };

    const path = ref<string>(route.params.path || constants.delimiter);
    const folders = ref<Folder[]>([]);
    const files = ref<File[]>([]);
    const fileContents = ref<string | undefined>(undefined);
    const fileSize = ref<number>(0);

    const isFile = computed<boolean>(() => {
      return typeof fileContents.value === 'string';
    });

    const error = ref<AxiosError<FileSystemResource> | undefined>(undefined);
    const errorMessage = computed<string>(() => {
      if (error.value) {
        if (error.value.response?.status === 404) {
          return t('deploymentViewer.error.notFound');
        }
        return error.value.message;
      }
      return '';
    });
    const axiosSource = ref<CancelTokenSource | undefined>(undefined);

    function linkRoute(_path: string, deploymentId?: string) {
      return {
        name: 'project-files',
        params: {
          projectId: props.project.id,
          branchId: props.branch.id,
          deploymentId: deploymentId ?? props.deployment?.id,
          path: _path,
        },
      };
    }

    function parsePath(_path: string): string {
      const nextPath = _path === constants.backPath ? parentPath(path.value) : _path;
      return nextPath.replace(/^\//, '');
    }

    function parentPath(_path: string): string {
      const segments = _path.split(constants.delimiter);
      segments.pop();
      return segments.join(constants.delimiter);
    }

    function isSamePath(_path: string, deploymentId: string) {
      return (
        props.deployment?.id === deploymentId && (_path || constants.delimiter) === (path.value || constants.delimiter)
      );
    }

    function navigatePath(_path: string, deployment?: Deployment, pushRoute: boolean = true) {
      const nextPath = parsePath(_path);
      const nextDeployment = deployment || props.deployment;
      const nextDeploymentId = deployment?.id || props.deployment?.id;
      if (nextDeploymentId && !isSamePath(nextPath, nextDeploymentId)) {
        if (apiCallIsRunning.value) {
          axiosSource.value?.cancel(`Cancelled request to navigate ${nextPath}`);
          axiosSource.value = axios.CancelToken.source();
        }
        const request = loadResource(nextPath, nextDeployment).finally(() => {
          if (pushRoute) {
            router.push(linkRoute(nextPath, nextDeploymentId) as any);
          }
          path.value = nextPath;
        });
        guardApiRequest(request);
      }
    }

    async function loadResource(_path: string, deployment?: Deployment) {
      if (deployment) {
        if ((props.deployment && props.deployment.id !== deployment.id) || !props.deployment) {
          projectsStore.deploymentViewerSettings = {
            projectId: deployment._projectId,
            branchId: deployment._branchId,
            deploymentId: deployment.id,
          };
        }
        return directoryApiClient.getJsonResource(_path).then(({ data }) => {
          displayResources(data);
        });
      }
    }

    function guardApiRequest(promise: Promise<void>): Promise<void> {
      error.value = undefined;
      apiCallIsRunning.value = true;
      return promise
        .catch((error) => {
          if (!axios.isCancel(error)) {
            error.value = error;
          }
        })
        .finally(() => {
          apiCallIsRunning.value = false;
        });
    }

    function displayResources(resource: FileSystemResource) {
      if (resource.file) {
        fileContents.value = resource.file.contents;
        fileSize.value = resource.file.size || 0;
      } else {
        folders.value = _.sortBy(resource.folder?.subfolders || [], ['name']);
        files.value = _.sortBy(resource.folder?.files || [], ['name']);
        fileContents.value = undefined;
        fileSize.value = 0;
      }
    }

    onMounted(() => {
      guardApiRequest(loadResource(path.value, props.deployment));
    });

    return {
      constants,
      loading,
      path,
      folders,
      files,
      fileContents,
      fileSize,
      isFile,
      error,
      errorMessage,
      linkRoute,
      navigatePath,
    };
  },
});
